import {h, render, Component} from "preact";
import Parser                 from "./parser";

class App extends Component {
  state = {vars: {}, parsed: 0, result: ""};

  onTimeValChange() {
    return (e) => {
      const val  = e.target.value;
      this.setState({val})

      this.calculateResult(val);
    };
  }

  calculateResult(val) {
    const vars = Object.values(this.state.vars)
      .reduce((a, k) => {
        try {
          return ({...a, [k.name]: Parser.parse(k.value)});
        } catch (e) {
          return {...a};
        }
      }, {});

    let result;

    try {
      result = Parser.parse(val, {...vars});
    } catch (e) {
      this.setState({result: `Result: 00:00`});

      return;
    }

    let h, m;
    h = Math.floor(result);
    m = Math.round((result - h) * 60);

    let hourText   = h.toString().padStart(2, "0");
    let minuteText = m.toString().padStart(2, "0");

    this.setState({result: `Result: ${hourText}:${minuteText}`});
  }

  onVarChange(k = 'name') {
    return (e) => {
      const val = e.target.value;

      const idx = e.target.name.match(/\d+/)

      const nextVar = {
        ...(this.state.vars[idx] || {name: null, value: null}),
        [k]: e.target.value,
      }

      this.setState({
        vars: {
          ...this.state.vars,
          [idx]: nextVar
        }
      });

      this.calculateResult(this.state.val);
    }
  }

  render() {
    return (
      <div className="wrapper">
        <h1>Simple Hours Calculator</h1>
        <div className="input-container">
          <input type="text" id="timeval" placeholder="Enter hour expression"
                 value={this.state.val}
                 onInput={this.onTimeValChange()}
          />
        </div>
        <div id="time_output">{this.state.result}</div>

        <div className="vars">
          <h2>Vars:</h2>
          <div className="var-container">
            <input type="text" name="var_name[0]" onInput={this.onVarChange('name')}/>
            <input type="text" name="value[0]" onInput={this.onVarChange('value')}/>
          </div>
          <div className="var-container">
            <input type="text" name="var_name[1]" onInput={this.onVarChange('name')}/>
            <input type="text" name="value[1]" onInput={this.onVarChange('value')}/>
          </div>
          <div className="var-container">
            <input type="text" name="var_name[2]" onInput={this.onVarChange('name')}/>
            <input type="text" name="value[2]" onInput={this.onVarChange('value')}/>
          </div>
        </div>
      </div>
    );
  }
}

render(<App/>, document.getElementById("root"));